import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './journalform.css';
import mixpanel from 'mixpanel-browser';

const RichTextToolbar = ({ contentEditableRef }) => {
  const [showToolbar, setShowToolbar] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });

  const applyStyle = (command) => {
    document.execCommand(command);
    contentEditableRef.current.focus();
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      if (selection.rangeCount > 0 && !selection.isCollapsed) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        setToolbarPosition({ top: rect.top - 40, left: rect.left + rect.width / 2 });
        setShowToolbar(true);
      } else {
        setShowToolbar(false);
      }
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  return (
    showToolbar && (
      <div className="rich-text-toolbar" style={{ top: toolbarPosition.top, left: toolbarPosition.left }}>
        <button type="button" onClick={() => applyStyle('bold')}>Bold</button>
        <button type="button" onClick={() => applyStyle('italic')}>Italic</button>
        <button type="button" onClick={() => applyStyle('underline')}>Underline</button>
        {/* Add more buttons for other styles as needed */}
      </div>
    )
  );
};

const JournalForm = ({ userId }) => {
  const [entry, setEntry] = useState('');
  const [timestamp, setTimestamp] = useState(null);
  const [loading, setLoading] = useState(false);
  const contentEditableRef = useRef();
  const [isFullscreen, setIsFullscreen] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('https://nlp-service2.itsalidoe.repl.co/entry', { text: entry, userId });
      setTimestamp(new Date());
      setEntry('');
      alert('Journal entry submitted successfully!');

      mixpanel.track('Journal Entry Submitted', {
        'User ID': userId,
        'Entry Length': entry.length,
      });

    } catch (error) {
      console.error(error);
      alert('Failed to submit the journal entry.');
    } finally {
      setLoading(false);
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement && document.documentElement.requestFullscreen) {
      contentEditableRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else {
      setIsFullscreen(!isFullscreen);
    }
  };

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleInput = (e) => {
    setEntry(e.target.innerHTML);
  };

  return (
    <div className={`journal-form-wrapper ${isFullscreen ? 'fullscreen' : ''}`}>
    <div>
      <form onSubmit={handleSubmit} className="journal-form">
        <h2 className="white-text">Start Writing:</h2>
        <div
          ref={contentEditableRef}
          onInput={handleInput}
          contentEditable
          required
          data-placeholder="Start writing, or paste a journal entry"
          className="journal-textarea"
        />
        <RichTextToolbar contentEditableRef={contentEditableRef} />
        {timestamp && (
        <p className="timestamp">Last saved at: {timestamp.toLocaleString()}</p>
      )}
      <div className="button-container">
        <div>
          <button type="button" onClick={toggleFullscreen} className="fullscreen-button">
            View Fullscreen
          </button>
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Saving...' : 'Submit'}
        </button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default JournalForm;
