// journalquery.js
import React, { useState } from 'react';
import axios from 'axios';
import './journalform.css';
import mixpanel from 'mixpanel-browser';


const JournalQuery = ({ userId }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [responseText, setResponseText] = useState('');
  const [loading, setLoading] = useState(false);


  const predefinedQueries = [
    'What are the most common themes I write about?',
    'How have my thoughts and feelings about certain topics changed over time?',
    'Are there patterns in the emotions that I express?',
    'What are some recurring words or phrases that I use in my writing?',
    'Are there any topics or themes that are strongly linked in my writing?',
    'Are there any patterns in the way I cope with stress or difficult situations?',
  ];

  const handleClick = (queryText) => {
    setQuery(queryText);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get(
        `https://nlp-service2.itsalidoe.repl.co/query?q=${query}&userId=${userId}`
      );
      console.log('Response data:', response.data);
      setResults(response.data.sourceDocuments);
      setResponseText(response.data.text);

      mixpanel.track('Journal Analysis Requested', {
        'User ID': userId,
        'Query': query,
      });
      
    } catch (error) {
      console.error(error);
      alert('Failed to fetch journal entries.');
    }
    setLoading(false);
  };
  

  return (
    <div className="container">
      <h2 className="white-text">Use AI to Analyze Journal Entries</h2>
      <div className="predefined-queries">
        {predefinedQueries.map((queryText, index) => (
          <button
            key={index}
            className="predefined-query"
            onClick={() => handleClick(queryText)}
          >
            {queryText}
          </button>
        ))}
      </div>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Enter your question here"
          className="query-input"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          required
        />
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Analyzing...' : 'Analyze'}
        </button>
      </form>
        <div
          style={{
            backgroundColor: 'white',
            padding: '1rem',
            borderRadius: '5px',
            marginTop: '1rem',
          }}
        >
          <p>{responseText}</p>
        </div>
    </div>
  );
};

export default JournalQuery;