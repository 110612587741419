import React from 'react';
import { useNavigate } from 'react-router-dom';
import JournalForm from './journalform';
import JournalQuery from './journalquery';
import './journaloptions.css';

const JournalOptions = ({ userId }) => {
  const navigate = useNavigate();

  return (
    <div className="options-container">
      <div className="left-column">
        <JournalForm userId={userId} />
      </div>
      <div className="right-column">
        <JournalQuery userId={userId} />
      </div>
      <div className="bottom-section">
        {/* Add the list of previous entries here */}
      </div>
    </div>
  );
};

export default JournalOptions;
