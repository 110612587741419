import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';
import JournalForm from './journalform';
import JournalQuery from './journalquery';
import JournalOptions from './JournalOptions';
import logo from './logotheresa.svg';
import mixpanel from 'mixpanel-browser';
const isProduction = process.env.NODE_ENV === 'production';

// Replace YOUR_TOKEN with your Project Token
mixpanel.init('92b44796f4d5bd592f02b8ea68d3c846', { debug: !isProduction });

const supabase = createClient('https://yeqodpyiecinvahvziub.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InllcW9kcHlpZWNpbnZhaHZ6aXViIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODE5MjU5NTMsImV4cCI6MTk5NzUwMTk1M30.VkM5BV9NDK2MryBvJsxfOkzIBDZJHqi7_CeL4KqzX1U');

function HomePage({ userId }) {
  return (
    <div className="container">
      <div className="top-section">
                <JournalForm userId={userId} />
      </div>
      <div className="bottom-section">
        <h2>What would you like to do with Theresa AI?</h2>
        <div>
          <Link to="/journal/query">
            <button className="submit-button button-spacing">Analyze</button>
          </Link>
          <Link to="/chat">
            <button className="submit-button">Chat</button>
          </Link>
        </div>
      </div>
    </div>
  );
}


function Chat() {
  return (
    <div className="chat-container">
    <div>
      <iframe
        src="https://ai-for-theresa.itsalidoe.repl.co"
        title="Chat App"
        width="100%"
        max-width="800px" // Add this line
        height="600px"
        style={{ border: 'none' }}
      ></iframe>
    </div>
    </div>
  );
}

function App() {
  const [session, setSession] = useState(null);

  // Add the toggleMenu function
  function toggleMenu() {
    const navMenu = document.querySelector('.nav-menu');
    const hamburger = document.querySelector('.hamburger');
    navMenu.classList.toggle('active');
    hamburger.classList.toggle('active');
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
       // Check if the user has signed up
    if (_event === 'SIGNED_IN') {
      // Track the sign-up event
      mixpanel.identify(session.user.id);
      mixpanel.track('Signed Up', {
        'Signup Type': 'Referral',
      });
    }
  });
  return () => subscription.unsubscribe();
}, []);

useEffect(() => {
  const navLinks = document.querySelectorAll(".nav-link");
  const navButtons = document.querySelectorAll(".submit-button");

  function closeMenu() {
    toggleMenu(); // Call toggleMenu instead of manually removing classes
  }

  navLinks.forEach((navLink) => {
    navLink.addEventListener("click", closeMenu);
  });

  navButtons.forEach((navButton) => {
    navButton.addEventListener("click", closeMenu);
  });

  return () => {
    navLinks.forEach((navLink) => {
      navLink.removeEventListener("click", closeMenu);
    });
    navButtons.forEach((navButton) => {
      navButton.removeEventListener("click", closeMenu);
    });
  };
}, []);

  async function signOut() {
    await supabase.auth.signOut();
  }

  console.log ('session', session);

  if (!session) {
    return (
      <div className="login-container">
         <div className="auth-container">
        <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa, hideSocialLogins: true }} providers={['Your Email Below']} />
      </div>
          </div>
    );
  } else {
    return (
      <div className="app-wrapper">
      <Router>
        <div className="App">
          <header className="header">
            <nav className="navbar">
            <Link to="/" className="nav-logo">
  <img src={logo} alt="Theresa AI" />
</Link>

              <ul className="nav-menu">
                <li className="nav-item">
                  <Link to="/chat" className="nav-link">Chat</Link>
                </li>
                <li className="nav-item">
                  <Link to="/journal" className="nav-link">My Journal</Link>
                </li>
                <li className="nav-item">
                  <button onClick={signOut} className="nav-link sign-out">Sign Out</button>
                </li>
              </ul>
              <div className="hamburger" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
            </nav>
          </header>
          <Routes>
  <Route path="/" element={<HomePage userId={session.user.id} />} />
  <Route path="/chat" element={<Chat />} />
  <Route path="/journal" element={<JournalOptions userId={session.user.id} />} />
  <Route path="/journal/form" element={<JournalForm userId={session.user.id} />} />
  <Route path="/journal/query" element={<JournalQuery userId={session.user.id} />} />
</Routes>

        </div>
      </Router>
      </div> 
    );
  }
}

export default App;
